  :root {
    --green: #00B5A3;
    --black: #000000;
    --text-light: rgba(0, 0, 0, 0.6);
  }

  .chakra-checkbox__label {
    font-size: 14px !important;
    width: 65%;
    margin: 0 1rem;
  }

  /* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none!important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none!important;
  -webkit-appearance: none;
}

video::-webkit-media-controls-overlay-play-button {
      display: none;
    }

    video::-webkit-media-controls {
    opacity: 0;
  } 
  
  /* @font-face {
    font-family: "BasierSquare-Bold";
    src: url("/fonts/BasierSquare-Bold.otf") format("opentype");
  }

  @font-face {
    font-family: "BasierSquare-Regular";
    src: url("/fonts/BasierSquare-Regular.otf") format("opentype");
  }

  @font-face {
    font-family: "OpenSans-Regular";
    src: url("/fonts/OpenSans-Regular.ttf") format("truetype");
  }
  @font-face {
    font-family: "OpenSans-SemiBold";
    src: url("/fonts/OpenSans-SemiBold.ttf") format("truetype");
  } */